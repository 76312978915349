import { Overlay } from "@angular/cdk/overlay";
import { ValidatorFunction } from "./../../share/function/validator.function";
import { AlertService } from "./../../share/service/alert.service";
import { MasterDataDataService } from "./../../share/data-service/master-data.data.service";
import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { MatSelectChange } from "@angular/material/select";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { PartnerService } from "src/app/share/service/partner.service";
import { FileFunction } from "src/app/share/function/file.function";

import {
	BUSINESS_TYPE,
	GENDER_TYPE,
	ID_TYPE,
	INSURED_TYPE,
	MARITAL_STATUS,
	SUM_INSURED_TYPE,
} from "src/app/share/constants/common.types";
import { TransferOwnershipDataService } from "src/app/share/data-service/transfer-ownership.data.service";
import { SubmitSuccessDialogComponent } from "../submit-success-dialog/submit-success-dialog.component";
import { SubmitFailDialogComponent } from "../submit-fail-dialog/submit-fail-dialog.component";
import { BeforeExpiredDialogComponent } from "../before-expired-dialog/before-expired-dialog.component";

SwiperCore.use([Pagination, Autoplay, Navigation]);
@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
	quotationSubmitted: boolean = false;
	quotationFormData: FormData = null;
	currentLang: string;
	partnerCode: string;
	paginationOption = {
		dynamicBullets: true,
	};
	autoplayOption = {
		delay: 5000,
		disableOnInteraction: false,
	};

	@ViewChild("stepper") stepper: MatStepper;
	vehicleFormGroup: FormGroup;
	custFormGroup: FormGroup;
	moreDetailsFormGroup: FormGroup;
	@ViewChild("vehicleForm") vehicleForm: NgForm;
	@ViewChild("custForm") custForm: NgForm;
	@ViewChild("moreDetailsForm") moreDetailsForm: NgForm;

	countryList: string[] = [];
	ncdList: number[] = [];
	filterCountryList: string[] = [];
	isCompany: boolean = false;
	isMalaysian: boolean = true;
	pdpaAgreement: boolean = false;

	idTypeOption = ID_TYPE;
	maritalStatusOption = MARITAL_STATUS;
	genderOption = GENDER_TYPE;
	businessTypeOption = BUSINESS_TYPE;
	sumInsuredTypeOption = SUM_INSURED_TYPE;
	typeOfSumOption = INSURED_TYPE;

	idType: Number = 1;
	utmCampaign: string;
	utmSource: string;
	utmMedium: string;
	utmContent: string;
	maxDob: Date = new Date();
	validPostcodes: string[] = [];

	carGrant: any;

	idTypeList = [
		{ id: 1, name: "common.malaysian" },
		{ id: 2, name: "common.foreigner" },
		{ id: 3, name: "common.company" },
	];

	typeOfInsuranceList = [
		// { id: "TP", name: "common.thirdPartyOnlyIns" },
		{ id: "TPFT", name: "common.TPFT" },
		{ id: "COM", name: "common.COM" },
	];

	constructor(
		gtmService: GoogleTagManagerService,
		private ngxSpinnerService: NgxSpinnerService,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private masterDataDataService: MasterDataDataService,
		private partnerService: PartnerService,
		private alertService: AlertService,
		private dialog: MatDialog,
		public overlay: Overlay,
		private transferOwnershipDataService: TransferOwnershipDataService,
	) {
		gtmService.pushTag({
			event: "car_lp",
		});
		this.initForm();
		this.getParams();
		this.ncdList = this.masterDataDataService.getNcdList();
	}

	private getParams() {
		let snapshotParam = this.activatedRoute.snapshot.queryParams;
		let lang: string = snapshotParam.lang;
		if (lang) {
			if (lang.toLocaleLowerCase() == "bm") {
				this.translate.use("bm");
			}
		}

		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;

		this.utmSource = snapshotParam.utm_source;
		this.utmMedium = snapshotParam.utm_medium;
		this.utmCampaign = snapshotParam.utm_campaign;
		this.utmContent = snapshotParam.utm_content;

		if (!this.utmSource) {
			let searchEngineList = ["google", "yahoo", "bing"];
			let referrer = document.referrer;
			if (
				referrer != window.location.href ||
				referrer.includes("policystreet.com")
			) {
				let searchEngine = searchEngineList.find((x) =>
					referrer.toLowerCase().includes(x),
				);
				if (searchEngine) {
					this.utmSource = searchEngine;
					this.utmMedium = "organic";
				} else {
					if (!referrer) this.utmSource = "direct";
					else {
						let url = new URL(referrer);
						this.utmSource = url.hostname;
						this.utmMedium = "referral";
					}
				}
			} else {
				this.utmSource = "direct";
			}
		}
	}

	private initForm() {
		this.vehicleFormGroup = new FormGroup({
			InsuranceType: new FormControl("COM", Validators.required),
			VehicleNo: new FormControl(null, Validators.required),
			PostCode: new FormControl(null, [Validators.required, (control: FormControl) => this.postcodeValidator(control)]),
			BusinessType: new FormControl(null, Validators.required),
			TypeOfSum: new FormControl("MV"),
			// includeRoadTax: new FormControl(false),
			// eHailingUsed: new FormControl(false),
		});

		this.custFormGroup = new FormGroup({
			IdentityNo: new FormControl(null, Validators.required),
			Name: new FormControl("", Validators.required),
			PolicIdentityNo: new FormControl(""),
			Nationality: new FormControl(null, Validators.required),
			Dob: new FormControl(null, Validators.required),
			Gender: new FormControl(null, Validators.required),
			MaritalStatus: new FormControl(null, Validators.required),
			AgentMobileNo: new FormControl(null, Validators.required),
			AgentEmail: new FormControl(null, [
				Validators.required,
				Validators.email,
			]),
		});

		this.moreDetailsFormGroup = new FormGroup({
			PreviousNCD: new FormControl(null, Validators.required),
			PreviousVehicleNo: new FormControl(null, Validators.required),
			SumInsuredType: new FormControl(
				this.sumInsuredTypeOption.recommended,
				Validators.required,
			),
			SumInsured: new FormControl(null),
		});

		let businessTypeVal = this.vehicleFormGroup.controls.BusinessType;
		let maritalStatusVal = this.custFormGroup.controls.MaritalStatus;
		let typeOfSumVal = this.vehicleFormGroup.controls.TypeOfSum;

		businessTypeVal.setValue(this.businessTypeOption.transferOwnership);
		maritalStatusVal.setValue(this.maritalStatusOption.single);
		this.setMalaysian();

		this.custFormGroup.controls.IdentityNo.valueChanges.subscribe((value) => {
			if (
				this.custFormGroup.controls.IdentityNo.valid &&
				this.isMalaysian &&
				!this.isCompany
			) {
				let date = moment(value.substring(0, 6), "YYMMDD");
				if (moment().isBefore(date)) {
					date.subtract(100, "year");
				}
				let lastChar = value.substring(11);
				this.custFormGroup.controls.Dob.setValue(date.toDate());
				let gender =
					lastChar % 2 ? this.genderOption.male : this.genderOption.female;
				this.custFormGroup.controls.Gender.setValue(gender);
			}
		});

		this.moreDetailsFormGroup.controls.SumInsuredType.valueChanges.subscribe(
			(value) => {
				if (value == this.sumInsuredTypeOption.recommended) {
					this.moreDetailsFormGroup.patchValue({
						SumInsured: null,
					});
				}
			},
		);

		businessTypeVal.valueChanges.subscribe(
			(value) => {
				if (value === this.businessTypeOption.renewal){
					typeOfSumVal.setValue(this.typeOfSumOption.agreedValue)
				}
			}
		);

		// pre-fetch the list of postcodes
		this.masterDataDataService.selectAllPostcodes().subscribe({
			next: (x) => {
			  this.validPostcodes = x;
			},
			error: (err) => {
			  this.alertService.openSnackBar(err.error);
			},
		  });
	}

	private postcodeValidator(control: FormControl) {
		const value = control.value;
	
		if (value != null && value.length === 5) 
		  return this.validPostcodes.includes(value) ? null : { customError: true };
		else
		  return { customError: true };
	  }

	private setNonCompanyValidation() {
		this.custFormGroup.controls.Dob.setValidators([Validators.required]);
		this.custFormGroup.controls.Gender.setValidators([Validators.required]);
		this.custFormGroup.controls.MaritalStatus.setValidators([
			Validators.required,
		]);

		this.custFormGroup.controls.Dob.updateValueAndValidity();
		this.custFormGroup.controls.Gender.updateValueAndValidity();
		this.custFormGroup.controls.MaritalStatus.updateValueAndValidity();
	}

	private setCompanyValidation() {
		this.custFormGroup.controls.Dob.setValidators([]);
		this.custFormGroup.controls.Gender.setValidators([]);
		this.custFormGroup.controls.MaritalStatus.setValidators([]);
		this.custFormGroup.controls.Nationality.setValidators([]);

		this.custFormGroup.controls.Dob.updateValueAndValidity();
		this.custFormGroup.controls.Gender.updateValueAndValidity();
		this.custFormGroup.controls.MaritalStatus.updateValueAndValidity();
		this.custFormGroup.controls.Nationality.updateValueAndValidity();
	}

	private getCountryList() {
		if (this.countryList.length != 0) {
			this.filterCountry();
			return;
		}
		this.masterDataDataService.getCountrySelection().subscribe({
			next: (x) => {
				this.countryList = x;
				this.filterCountry();
			},
			error: (err) => {
				this.alertService.openSnackBar(err.error);
			},
		});
	}
	private resetForm() {
		this.vehicleForm.resetForm();
		this.vehicleFormGroup.reset();
		this.custForm.resetForm();
		this.custFormGroup.reset();

		if (
			this.vehicleFormGroup.controls["BusinessType"].value ===
			this.businessTypeOption.transferOwnership
		) {
			this.moreDetailsFormGroup.reset();
			this.moreDetailsForm.resetForm();
		}

		this.custFormGroup.controls.AgentMobileNo.setValue(" ");
		this.vehicleFormGroup.controls.BusinessType.setValue(
			this.businessTypeOption.transferOwnership,
		);
		this.custFormGroup.controls.AgentMobileNo.updateValueAndValidity();
		this.idType = 1;
		this.deleteCarGrantFile();
		this.initForm();

		this.stepper.reset();
	}

	private setMalaysian() {
		this.isCompany = false;
		this.isMalaysian = true;
		this.custFormGroup.controls.IdentityNo.setValue(null);
		this.custFormGroup.controls.IdentityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.custFormGroup.controls.Dob.disable();
		this.custFormGroup.controls.Gender.disable();
		this.custFormGroup.controls.IdentityNo.updateValueAndValidity();
		this.custFormGroup.controls.Nationality.setValidators([]);
		this.custFormGroup.patchValue({
			Nationality: this.idTypeOption.malaysian,
		});
		this.custFormGroup.controls.Nationality.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setNonMalaysian() {
		this.isCompany = false;
		this.isMalaysian = false;
		this.getCountryList();
		this.custFormGroup.controls.IdentityNo.setValidators([Validators.required]);
		this.custFormGroup.controls.Nationality.setValidators([
			Validators.required,
		]);
		this.custFormGroup.controls.Dob.enable();
		this.custFormGroup.controls.Gender.enable();
		this.custFormGroup.controls.Nationality.updateValueAndValidity();
		this.custFormGroup.controls.IdentityNo.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setIsCompany() {
		this.isCompany = true;
		this.isMalaysian = true;
		this.custFormGroup.controls.IdentityNo.setValidators([Validators.required]);
		this.custFormGroup.controls.IdentityNo.updateValueAndValidity();
		this.custFormGroup.controls.Name.setValidators([]);
		this.custFormGroup.controls.Name.updateValueAndValidity();

		this.setCompanyValidation();
	}

	idTypeChange(e: MatSelectChange) {
		let value = e.value;
		switch (value) {
			case 1:
				this.setMalaysian();
				break;
			case 2:
				this.setNonMalaysian();
				break;
			case 3:
				this.setIsCompany();
				break;
		}
	}

	getIdentityNoLabel() {
		if (this.isMalaysian && !this.isCompany) return "common.icNoLabel";
		else if (!this.isMalaysian) return "common.passportLabel";
		return "common.businessRegistrationNoLabel";
	}

	getNameLabel() {
		if (this.isMalaysian && !this.isCompany) return "common.namePerIc";
		else if (!this.isMalaysian) return "common.namePerPassport";
		return "common.companyName";
	}

	getIdentityNoMasking() {
		if (this.isMalaysian && !this.isCompany) return "000000000000";
		return "";
	}

	filterCountry(val: string = null) {
		if (val) {
			let txt = val.trim().toLowerCase();
			this.filterCountryList = this.countryList.filter((x) =>
				x.trim().toLowerCase().startsWith(txt),
			);
		} else this.filterCountryList = Object.assign([], this.countryList);
	}

	submitFormData() {
		if (!this.vehicleFormGroup.valid || !this.custFormGroup.valid) return;
		this.quotationFormData = this.appendFormData();

		if (this.vehicleFormGroup.controls["BusinessType"].value === this.businessTypeOption.renewal
			&& this.vehicleFormGroup.controls["TypeOfSum"].value === this.typeOfSumOption.agreedValue
		) {
			this.ngxSpinnerService.show();
			this.transferOwnershipDataService
				.submit(this.quotationFormData)
				.subscribe({
					next: (x) => {
						this.ngxSpinnerService.hide();

						if (x.isSuccess) {
							this.dialog.open(SubmitSuccessDialogComponent, {
								panelClass: "success-dialog",
								//data: x.data,
								data:
								{
									businessType: this.vehicleFormGroup.value.BusinessType,
									email: this.custFormGroup.value.AgentEmail
								}
							});
						} else {
						  if (x.isVehicleOverage) {
							this.dialog.open(SubmitFailDialogComponent, {
								data: {
								  isWithEHailing: x.isEhailing,
								},
								panelClass: "fail-dialog",
							});
						  } else {
							this.dialog.open(BeforeExpiredDialogComponent, {
								panelClass: "",
								//data: x.data,
								data:
								{
									businessType: this.vehicleFormGroup.value.BusinessType,
									email: this.custFormGroup.value.AgentEmail
								}
							});
						  }
						}

						this.resetForm();
					},
					error: (err) => {
						this.ngxSpinnerService.hide();
						this.alertService.openSnackBar("Please contact policy street.");
					},
				});
		} else {
			this.quotationSubmitted = true;
		}
	}

	private appendFormData() {
		let fd: any = new FormData();
		let custValue = this.custFormGroup.getRawValue();
		// let snapshotParam = this.activatedRoute.snapshot.queryParams;
		let vehicleValue = this.vehicleFormGroup.getRawValue();
		// Only for transfer ownership

		// Only for transfer ownership
		if (
			vehicleValue.BusinessType === this.businessTypeOption.transferOwnership
		) {
			if (!this.moreDetailsFormGroup.valid) return;
			// Loop through Step 3 Form
			for (const key in this.moreDetailsFormGroup.value) {
				if (key !== "SumInsured") {
					fd.append(key, this.moreDetailsFormGroup.value[key]);
				}
			}
			if (this.carGrant) {
				fd.append("VehicleGrant", this.carGrant);
			}
			if (this.moreDetailsFormGroup.value.SumInsured) {
				fd.append("SumInsured", this.moreDetailsFormGroup.value.SumInsured);
			}
		}

		// For Renewal and Transfer Ownership
		// Loop through Step 1 Form
		for (const key in this.vehicleFormGroup.value) {
			fd.append(key, this.vehicleFormGroup.value[key]);
		}
		// Loop through Step 2 Form
		for (const key in this.custFormGroup.value) {
			if (key !== "Gender" && key !== "Dob" && key !== "MaritalStatus") {
				fd.append(key, this.custFormGroup.value[key]);
			}
		}
		if (custValue.Gender) {
			fd.append("Gender", custValue.Gender);
		}
		if (custValue.Dob) {
			fd.append("Dob", custValue.Dob.toISOString());
		}
		if (custValue.MaritalStatus) {
			fd.append("MaritalStatus", custValue.MaritalStatus);
		}
		fd.append("IsMalaysian", this.isMalaysian);
		fd.append("IsCompany", this.isCompany);
		fd.append("Language", this.translate.currentLang);

		return fd;
	}

	whatsppRedirect() {
		const whatsappNumber = 60182822320;
		const entityName = "Policystreet";
		let whatsappContent = "";

		let lang = this.translate.currentLang == "en" ? "en" : "ms";
		whatsappContent =
			lang == "ms"
				? `Selamat datang ke ${entityName}! Bagaimana kami boleh bantu anda?
    1. Saya perlukan maklumat lanjut tentang polisi insurans tertentu.
    2. Insurans Motor - Tiada maklumat kereta ditemui.
    3. Insurans Motor - Jumlah Diinsuranskan.
    4. Insurans Motor – Pilihan syarikat insurans lain.
    5. Berbincang dengan ejen ${entityName}.
    Sila taip 1-5 berdasarkan permintaan di atas untuk mula.
    `
				: `Welcome to ${entityName}! How may we help you?
      1. I need more info on a particular insurance policy.
      2. Motor insurance - car info not found.
      3. Motor insurance - sum insured.
      4. Motor insurance - other insurer choices.
      5. Talk to a ${entityName} agent.
      Please type 1-5 based on the request above to start.`;

		let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
			whatsappContent,
		)}&source=&data=&app_absent=&lang=${lang}`;

		window.open(url, "_blank");
	}

	handleFileInput(e) {
		if (e.target.files && e.target.files[0]) {
			let file: File = e.target.files[0];
			if (!FileFunction.isImageOrPdf(file)) {
				this.alertService.openSnackBar(
					`Fail to upload ${file.name} - just accept image & pdf only`,
				);
				return;
			}
			// save file here to state
			this[e.target.name] = file;
		}
		e.target.value = "";
	}

	deleteCarGrantFile() {
		this.carGrant = null;
	}

	isRenewalOptionChosen(): boolean {
		return this.vehicleFormGroup.get('BusinessType').value === this.businessTypeOption.renewal;
	  }

}
